import React from "react"
import Layout from "../components/layout";
import Hero from "../components/hero";
import galleryStyles from "../scss/gallery.module.scss";
import { dynamic_content_url } from '../config.json';

function PopupView({ close, style, matterport_id, price, address }) {
  return (
    <div className={galleryStyles.backdrop} style={style}>
      <div className={galleryStyles.popup_display}>
        <div className={galleryStyles.popup_header}>
          <div className={galleryStyles.popup_header_left}>
            <div className={galleryStyles.closeBtn} onClick={close}>×</div>
            Preview
          </div>
          <div className={galleryStyles.popup_header_right}>
            <div className={galleryStyles.popup_price}>{price}</div>
            <div className={galleryStyles.popup_address}>{address}</div>
          </div>
        </div>
        <div className={galleryStyles.matterport_embed}>
          {matterport_id !== "" && <iframe 
            title={"matterport-" + matterport_id} 
            style={{ height: '70vh', transform: 'translateY(0px)' }}
            width="100%"
            resize="both"
            overflow="hidden"
            allowfullscreen="1"
            src={"https://my.matterport.com/show/?play=1&m=" + matterport_id} 
            frameborder="1" 
            allowfullscreen>
          </iframe>}
        </div>
      </div>
    </div>
  );
}

function GridItem({ homeObj, showPopup }) {
  let { matterport_id, price, address, image_url } = homeObj;
  return (
    <div 
      className={galleryStyles.gridItem} 
      style={{ backgroundImage: `url(${image_url}` }}>
      <div className={galleryStyles.gridHeader}>
        {address}
      </div>
      <div className={galleryStyles.gridFooter}>
        {price}
        <div 
          onClick={() => showPopup(homeObj)} 
          className={galleryStyles.detailBtn}>
          View Details
        </div>
      </div>
    </div>
  )
}

/**
 * The gallery page. Will display all 3D virtual home
 * listings, and is where much of the content should go.
 */
export default class Gallery extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      popup: {
        active: false,
        id: "",
        price: "",
        address: ""
      },
      homes: []
    }
  }

  componentDidMount() {
    fetch(dynamic_content_url)
      .then(response => response.json())
      .then(data => this.setState({ homes: data.gallery }));
  }

  render() {
    let { id, active, address, price } = this.state.popup;
    return (
      <React.Fragment>
        <PopupView 
          close={() => this.setState({ popup: { active: false, id: "" }})} 
          matterport_id={id}
          price={price}
          address={address}
          style={{ display: active ? 'flex' : 'none' }}/>
        <Layout>
          <Hero>Gallery</Hero>
          <div className="container">
            <div className="section">
              <div className={galleryStyles.text}>
                Check out some of the homes we currently host virtual tours for.
              </div>
              <div className={galleryStyles.grid}>
                {this.state.homes.map(item => (
                  <GridItem 
                    key={item.matterport_id} 
                    homeObj={item}
                    showPopup={(homeItem) => {
                      this.setState({
                        popup: {
                          active: true,
                          id: homeItem.matterport_id,
                          price: homeItem.price,
                          address: homeItem.address
                        }
                      })
                    }} />
                ))}
              </div>
            </div>
          </div>  
        </Layout>
      </React.Fragment>
    );
  }
}
